import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useSpacesHook from '../../../api/spaces'
import ViewSpaces from '../../../components/admin/spaces/ViewSpaces';
import FormSpaces from '../../../components/admin/spaces/FormSpaces';

import {
    Spinner,
    Pagination,
    Message,
    Confirm, Search,
} from '../../../components'

const Spaces = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')

    const {getSpaces, postSpace, updateSpace, deleteSpace} = useSpacesHook({
        page,
        q,
    })

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            confirmed: true,
            blocked: false,
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getSpaces

    const {
        isLoading: isLoadingUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateSpace

    const {
        isLoading: isLoadingDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteSpace

    const {
        isLoading: isLoadingPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postSpace

    const formCleanHandler = () => {
        reset();  // Reset form via react-hook-form
        setEdit(false);
    };

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate) {
            setEdit(false);
        }
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        if (q.trim() || page) {
            refetch();
        }
    }, [page, q, refetch]);

    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }

    const submitHandler = async (data) => {
        try {
            // console.log(data);
            if (edit) {
                await mutateAsyncUpdate({
                    _id: id,
                    name: data.name,
                    address: data.address,
                    description: data.description,
                    phone: data.phone,
                    postal: data.postal,
                    floor: data.floor,
                    glocation: data.glocation,
                    lat: data.lat,
                    lng: data.lng,
                    admin1: data.admin1,
                    note: data.note,
                    website: data.website,
                    image: data.image,
                    category: data.category,
                    tags: data.tags,
                    email: data.email,
                    confirmed: data.confirmed,
                    blocked: data.blocked
                });
            } else {
                await mutateAsyncPost(data);
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT POST');
            } else {
                console.log('SUCCESSFULL NEW POST');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };

    const editHandler = (space) => {
        setId(space._id)
        setEdit(true)
        setValue('name', space.name)
        setValue('address', space.address)
        setValue('description', space.description)
        setValue('phone', space.phone)
        setValue('postal', space.postal)
        setValue('floor', space.floor)
        setValue('glocation', space.glocation)
        setValue('lat', space.lat)
        setValue('lng', space.lng)
        setValue('admin1', space.admin1)
        setValue('note', space.note)
        setValue('website', space.website)
        setValue('image', space.image)
        setValue('category', space.category)
        setValue('tags', space.tags)
        setValue('email', space.email)
        setValue('confirmed', space.confirmed)
        setValue('blocked', space.blocked)
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Spaces</title>
                    <meta property='og:title' content='Spaces' key='title'/>
                </Helmet>
            </HelmetProvider>

            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    Events List{' '}
                    <sup className='fs-6'> [{data && data.total}] </sup>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='btn add-new-entry-button align-self-end'
                        data-bs-toggle='modal'
                        data-bs-target='#spaceModal'
                        onClick={() => formCleanHandler(true)}
                    >
                        Add New Event
                    </button>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder='Search by Name, Address, Category'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>

            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <ViewSpaces
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    isLoadingDelete={isLoadingDelete}
                />
            )}

            <FormSpaces
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                errors={errors}
                isLoadingUpdate={isLoadingUpdate}
                isLoadingPost={isLoadingPost}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                watch={watch}
                error={error}
                setValue={setValue}
            />


        </>
    )
}

export default Spaces
